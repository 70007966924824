/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_speciality-cheeseshop.png"
      title="Travaillez avec une caisse enregistreuse pour fromagerie"
      name="Fromagerie"
      label="Fromagerie"
      description="La caisse enregistreuse répond parfaitement aux besoins de la fromagerie : fiches produits spécialisées fromager, caisse enregistreuse tactile connectée à une balance et à un lecteur codes-barres, impression d'étiquettes produits, tout est pensé pour vous !"
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_speciality-cheeseshop_catalog.png',
              title: 'Fiches produits spécialisées',
              content:
                'Produit en vrac, à la pièce, DLC, recensez tout ce dont vous avez besoin sur votre catalogue',
            },
            {
              media: 'icon_speciality-cheeseshop_bulkproduct.png',
              title: 'Gestion du vrac',
              content: 'Encaissez facilement vos produits de fromagerie avec la balance connectée',
            },
            {
              media: 'icon_speciality-cheeseshop_barcode.png',
              title: 'Lecteur codes-barres',
              content: 'Scannez vos produits pour les identifier et éviter les erreurs de saisie',
            },
            {
              media: 'icon_speciality-cheeseshop_productlabel.png',
              title: 'Étiquettes produits',
              content: "Etiquettez vos produits et gagnez du temps à l'encaissement",
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_speciality-cheeseshop_product.png',
              alt: 'Fiches produits spécialisées',
              label: 'Fiches produits spécialisées',
              title: 'Un catalogue avec des fiches produits spécialisées pour fromagerie',
              content:
                "Avec le logiciel de caisse enregistreuse pour fromager, bénéficiez d'un catalogue pensé pour tous types de produits vendus : les fromages, les produits laitiers, les vins, les paniers garnis, etc. Gérez aussi les différentes unités de mesure et formats : au vrac au kilo ou au litre, à la pièce, en panier garni.",
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_speciality-cheeseshop_weightstation.jpg',
              alt: 'Gestion du vrac',
              label: 'Gestion du vrac',
              title: 'Une caisse enregistreuse tactile avec balance connectée',
              content:
                "Pour encaisser facilement les produits en vrac, bénéficiez d'une balance connectée à votre caisse enregistreuse tactile. Pesez rapidement vos produits, ajoutez-les au panier en caisse et finalisez la vente. Enfin, imprimez ou envoyez le ticket de caisse au client.",
            },
          ],
        },
        {
          name: 'winobox',
          title: "Besoin d'un devis personnalisé pour équiper votre fromagerie avec Wino ?",
          media: 'photo_askquotation.png',
          content:
            'Il nous tient à coeur de répondre à vos besoins. Nous serions ravis de vous rencontrer !',
          buttonText: 'Demander un devis',
          buttonLink: '/devis',
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_speciality-cheeseshop_barcodescanner.png',
              alt: 'Douchette connectée',
              label: 'Lecteur codes-barres',
              title: 'Un lecteur codes-barres pour scanner rapidement vos produits de fromagerie',
              content:
                "Le lecteur de codes-barres est le matériel indispensable du fromager. En identifiant vos produits rapidement grâce au lecteur, vous gagnez un temps précieux à l'encaissement et évitez les erreurs de saisie de stock. In fine, concentrez-vous sur ce qui vous tient à coeur : conseiller vos clients en boutique et sourcer vos nouvelles pépites.",
            },
            {
              decoration: 'alwadood',
              media: 'screenshot_speciality-cheeseshop_productlabel.png',
              alt: 'Étiquettes produits',
              label: 'Étiquettes produits',
              title: 'Des étiquettes produits imprimées depuis le logiciel de caisse',
              content:
                "Pour gagner du temps à l'encaissement et présenter les produits de votre fromagerie, rien de tel que d'étiqueter vos produits. Avec la caisse enregistreuse sur tablette, imprimez des étiquettes produits avec codes-barres à coller sur les emballages de vos fromages et d’autres produits. Wino vous donne les moyens de développer votre activité sereinement.",
            },
          ],
        },
      ]}
    />
  )
}
